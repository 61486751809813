<template>
  <entity-card
    :tabs="tabs"
    :tabs-items="tabsItems"
    :title="item.name"
    :image="image"
    :actions="actions"
    :loading="loading"
    @click:close="handleClose"
  >
    <template #header:status-bar>
      <div class="mr-2" :class="{ 'red--text': !item.enabled }">
        {{ item.enabled ? 'Enabled' : 'Disabled' }}
      </div>
    </template>
  </entity-card>
</template>

<script>
import EntityCard from '@/components/entity-card';
import {
  computed,
  onMounted,
  provide,
  toRefs,
  watch
} from '@vue/composition-api';

import { usePlanMenu } from '@/modules/plans/compositions/planMenu';

import { usePlan } from '@/modules/plans/compositions/plan';
import { useMap } from '@/compositions/map';
import { onBeforeUnmount } from 'vue-demi';

export default {
  name: 'PlanCard',
  components: {
    EntityCard
  },
  props: {
    planId: {
      type: [Number, String],
      required: true
    }
  },
  setup(props, { root }) {
    const { planId } = toRefs(props);

    const {
      plans: { setMapToPlanCenter, selectPlans, showedPlanIds }
    } = useMap();

    watch(
      () => planId.value,
      planId => {
        selectPlans([planId]);
        showedPlanIds.value = [...new Set([...showedPlanIds.value, planId])];
        setMapToPlanCenter(planId);
      },
      {
        immediate: true
      }
    );

    const { item, load, loading } = usePlan(planId);

    const tabs = [
      {
        id: 'general',
        title: 'General'
      },
      {
        id: 'geo',
        title: 'Geo'
      },
      {
        id: 'notifications',
        title: 'Notifications'
      }
      // {
      //   id: 'access',
      //   title: 'Access'
      // }
    ];
    const image = computed(() => item.value.url || '/img/object.png');
    const tabsItems = [
      () => import('./general/index'),
      () => import('./geo/index'),
      {
        component: () =>
          import('@/modules/notifications/ui/RecentlyNotificationList'),
        props: { objectId: props.planId }
      }
      // () => import('./access/index')
    ];
    const handleClose = () => {
      const { planId, ...params } = root.$route.params;
      const { st, ...query } = root.$route.query;
      root.$router.push({
        name: 'plans',
        params,
        query
      });
    };

    onMounted(async () => {
      await load();
    });

    onBeforeUnmount(() => {
      selectPlans([]);
    });

    provide('entity', item);
    provide('entityId', props.planId);

    const itemActions = computed(() => usePlanMenu({ entity: item.value }));

    return {
      tabs,
      image,
      actions: itemActions,
      handleClose,
      tabsItems,
      loading,
      item
    };
  }
};
</script>
